<!--<h5 mat-dialog-title class="mat-subtitle-1">{{ title }}</h5>-->
<h5 mat-dialog-title style="text-align: center;">{{ title }}</h5>

<div mat-dialog-content class="mat-subtitle-2 lh-16">
  {{ message }}
</div>
<div mat-dialog-actions class="p-24 p-t-0 justify-content-between">
  <button mat-stroked-button color="warn" (click)="cancel()">No</button>
  <button mat-flat-button color="primary" (click)="confirm()" cdkFocusInitial>Yes</button>
</div>
