export class User {
  adm_id: number;
  adm_type: string;
  adm_name: string;
  adm_brandname: string;
  adm_username: string;
  adm_contact: string;
  adm_status: string;
  adm_image: string;
  adm_cdate: string;
  adm_udate: string;

  constructor(value: any = {}) {
    Object.assign(this, value);
  }
}
